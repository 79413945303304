<template>
  <div class="menus">
    <div class="tian">
      <el-button
        type="primary"
        plain
        icon="el-icon-plus"
        size="mini"
        @click="addition()"
        style="float: left"
        v-hasPermi="['learn:column:add']"
        >添加
      </el-button>
    </div>

    <!-- ---------------添加/编辑----------------------------------- -->
    <template>
      <el-dialog :title="title" :visible.sync="dialogFormVisible" width="600px">
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
            <el-input
              v-model="form.title"
              placeholder="请输入标题"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="菜单"
            :label-width="formLabelWidth"
            prop="parentid"
          >
            <treeselect
              v-model="form.parentid"
              :options="schoolOptions"
              :normalizer="normalizer"
              placeholder="请选择"
            />
          </el-form-item>
          <el-form-item
            label="排序"
            :label-width="formLabelWidth"
            prop="displayorder"
          >
            <!-- <el-input v-model="form.displayorder"  placeholder="请输入排序" autocomplete="off"></el-input> -->
            <el-input-number
              v-model="form.displayorder"
              controls-position="right"
              :min="1"
              style="width: 100%"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="描述" :label-width="formLabelWidth">
            <el-input
              v-model="form.description"
              placeholder="请输入说明"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="内容" :label-width="formLabelWidth">
            <el-input
              v-model="form.content"
              placeholder="请输入内容"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="首页展示"
            :label-width="formLabelWidth"
            prop="ishome"
          >
            <el-radio-group v-model="form.ishome">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="状态"
            :label-width="formLabelWidth"
            prop="status"
          >
            <el-radio-group v-model="form.status">
              <el-radio label="1">正常</el-radio>
              <el-radio label="0">停用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitForm()">确 定</el-button>
        </div>
      </el-dialog>
    </template>
    <el-table
      :data="tableData"
      style="width: 100%"
      row-key="id"
      :header-cell-style="{ background: '#F8F8F9', color: '#515A6E' }"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="title" label="标题" width="250"> </el-table-column>
      <el-table-column
        prop="description"
        label="描述"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column prop="ishome" label="首页展示">
        <template slot-scope="scope" v-if="scope.row.parentid === 0">
          <el-tag type="primary" plain v-if="scope.row.ishome === '1'"
            >是</el-tag
          >
          <el-tag type="danger" plain v-else>否</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" width="180">
        <template slot-scope="scope">
          <!-- <span  >{{scope.row.status}}</span> -->
          <el-tag type="primary" plain v-if="scope.row.status === '1'"
            >正常</el-tag
          >
          <el-tag type="danger" plain v-else>停用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleEdit(scope.$index, scope.row)"
            v-hasPermi="['learn:column:edit']"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.$index, scope.row)"
            v-hasPermi="['learn:column:remove']"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!--     
        <el-pagination center background layout="prev, pager, next, sizes, total, jumper" :page-sizes="pageSizes"
        :page-size="pagesize" :page-count="pageCount" :total="total" :current-page.sync="pageNo" @current-change="handleCurrentChange"
        @size-change="handleSizeChange" ref="pagination" @next-click="pages" @prev-click="pages">
      </el-pagination> -->
  </div>
</template>



<script>
import request from "../../../utils/request.js";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: { Treeselect },
  data() {
    return {
      pagesize: 10, //分页默认一页显示15条数据
      currpage: 1, //分页默认显示第1页数据
      pageSizes: [10, 30, 50], //分页可选择一页多少条数据
      pageNo: 1, //强行第一页
      total: 0, //共多少条数据从接口获取
      pageCount: 0, //共多少页数据从接口获取
      formLabelWidth: "120px",
      tableData: [],
      // 部门树选项
      schoolOptions: [],
      dialogFormVisible: false,
      editFormVisible: false,
      resetuserName: "", //搜索名称
      resetphone: "", //搜索电话
      resetstatus: "", //搜索状态
      deptIdsss: "",
      title: "",
      parentid: "",
      displayorder: "",
      description: "",
      content: "",
      ishome: "1",
      status: "1",
      roleId: "",
      roleList: [],
      treeData: "",
      treeDataValue: "",
      mineStatus: "",
      mineStatusValue: [],
      clickMsg: "1", //搜索数据分页和查询全部数据分页开关 1 查询所有数据  2 搜索数据  3 角色查询
      defaultProps: {
        children: "children",
        label: "title",
      },
      title: "",
      editForm: {},
      form: {},
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        parentid: [
          { required: true, message: "请选择菜单", trigger: "change" },
        ],
        displayorder: [
          { required: true, message: "请输入排序", trigger: "change" },
        ],
        ishome: [
          { required: true, message: "请选择首页展示", trigger: "change" },
        ],
        status: [{ required: true, message: "请选择状态", trigger: "change" }],
      },
    };
  },
  created() {
    this.info();
  },
  methods: {
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.title,
        isDisabled: node.status == 0,
        children: node.children,
      };
    },
    handleCheckChange() {
      this.mineStatusValue = this.$refs.tree
        .getHalfCheckedKeys()
        .concat(this.$refs.tree.getCheckedKeys());
    },
    bianCheckChange() {
      this.mineStatusValue = this.$refs.bian
        .getHalfCheckedKeys()
        .concat(this.$refs.bian.getCheckedKeys());
    },

    //获取列表数据
    info() {
      this.clickMsg = "1"; //搜索数据分页和查询全部数据分页开关 false 查询所有数据分页

      return request({
        url: "articleCategory/queryArticleCategory",
        method: "get",
      }).then((res) => {
        if (res.status === 200) {
          this.tableData = this.handleTree(res.data, "id", "parentid");
        }
      });
    },

    //清空添加表单
    empty() {
      this.title = "";
      this.parentid = "";
      this.displayorder = "";
      this.description = "";
      this.content = 0;
      (this.ishome = ""), (this.status = "");
    },
    //搜索
    handleQuery() {
      this.clickMsg = "2"; //搜索数据分页和查询全部数据分页开关 false 查询所有数据分页
      let dats = {
        pageNum: this.currpage,
        pageSize: this.pagesize,
        username: this.resetuserName,
        phone: this.resetphone,
        status: this.resetstatus,
      };
      return request({
        url: "admin/list",
        method: "get",
        params: dats,
      }).then((res) => {
        if (res.status == 200) {
          this.tableData = res.data.data;
          this.pageCount = res.data.pageBean.pageCount; //接口获取到的共多少页
          this.total = res.data.pageBean.count; //接口获取到的共多少条
        }
      });
    },
    //搜索重置
    resetQuery() {
      this.resetuserName = ""; //搜索名称
      this.resetphone = ""; //搜索电话
      this.resetstatus = ""; //搜索状态
    },

    //添加弹窗
    addition() {
      this.schoolOptions = [];
      this.title = "添加栏目";
      this.resetForm("form");
      this.form = {};
      request({
        url: "articleCategory/queryColumn",
        method: "get",
      }).then((res) => {
        const school = { id: 0, title: "主类目", children: [] };
        school.children = this.handleTree(res.data, "id", "parentid");
        this.schoolOptions.push(school);
      });
      this.dialogFormVisible = true;
      this.parentid = undefined;
    },
    // 添加弹窗确定提交
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id !== undefined) {
            return request({
              url: "articleCategory/update",
              method: "put",
              data: this.form,
            })
              .then((res) => {
                if (res.status == 200) {
                  this.$message({
                    message: "修改成功",
                    type: "success",
                  });
                  this.info();
                  this.mineStatusValue = [];
                  this.dialogFormVisible = false;
                }
              })
              .catch(() => {});
          } else {
            return request({
              url: "articleCategory/addArticleCategory",
              method: "post",
              data: this.form,
            })
              .then((res) => {
                if (res.status == 200) {
                  this.$message({
                    message: "添加成功",
                    type: "success",
                  });
                  this.info();
                  this.empty();
                  this.dialogFormVisible = false;
                }
              })
              .catch(() => {});
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    //编辑弹窗
    handleEdit(index, row) {
      this.resetForm("form");
      this.editForm.parentid = undefined;
      this.dialogFormVisible = true;
      this.title = "编辑栏目";
      this.schoolOptions = [];
      request({
        url: "articleCategory/queryColumn",
        method: "get",
      }).then((res) => {
        const school = { id: 0, title: "主类目", children: [] };
        school.children = this.handleTree(res.data, "id", "parentid");
        this.schoolOptions.push(school);
      });
      request({
        url: "articleCategory/queryById/" + row.id,
        method: "get",
      }).then((res) => {
        this.form = res.data;
      });
    },

    //删除
    handleDelete(index, row) {
      // console.log(row);
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            articleCategoryId: row.id,
          };
          return request({
            url: "articleCategory/delete",
            method: "delete",
            data: data,
          }).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.info();
            }
          });
        })
        .catch(() => {});
    },
    pages() {
      if (this.clickMsg == "1") {
        //如果 clickMsg = 1 的话
        this.info(); //分页查询所有数据
      } else if (this.clickMsg == "2") {
        //如果 clickMsg = 2 的话
        this.inquiry(); //分页查询搜索数据
      } else if (this.clickMsg == "3") {
        //如果 clickMsg = 3 的话
        this.redactSubmint(); //分页角色查询数据
      }
    },

    /* 分页方法-------------- */
    handleCurrentChange(cpage) {
      this.currpage = cpage;
      this.pages(); //查询接口渲染数据
    },
    handleSizeChange(psize) {
      this.pagesize = psize;
      this.pages(); //查询接口渲染数据
    },
  },
};
</script>

<style scoped>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.tian {
  width: 100%;
  height: 60px;
}
.el-dialog {
  width: 500px !important;
}
.el-form {
  height: auto;
}
.el-pagination {
  text-align: right;
}
::v-deep .el-form-item__content {
  text-align: left !important;
}
</style>
 
